import { format } from "date-fns"
import React from "react"
// import { buildImageObj } from "../lib/helpers"
// import { imageUrlFor } from "../lib/image-url"
// import PortableText from "./portableText"
// import AuthorList from "./author-list"
import { Heading, Text } from "../ziibo"
import BasePortableText from "@sanity/block-content-to-react"
import clientConfig from "../../client-config"
import serializers from "./serializers"

function PodcastEpisode({ title, publishedAt, anchorFmLink, _rawExcerpt }) {
	return (
		<article style={{ margin: "24px auto", width: "960px" }}>
			<Heading style={{ textAlign: "center" }}>{title}</Heading>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				{publishedAt && (
					<>
						<Text fontSize={200}>{format(new Date(publishedAt), "MMMM do, yyyy")}</Text>
					</>
				)}
			</div>
			<div style={{ marginTop: "4.8rem" }}>
				<BasePortableText blocks={_rawExcerpt} serializers={serializers} {...clientConfig.sanity} />
			</div>
			<div style={{ display: "flex", justifyContent: "center", margin: "5.6rem 0" }}>
				<iframe src={anchorFmLink} title={title} height="102px" width="600px" frameborder="0" scrolling="no"></iframe>
			</div>
		</article>
	)
}

export default PodcastEpisode
